
import { Options, Vue } from "vue-class-component";
import { bundleAPI } from "@/utils/apiCalls";
import * as types from "@/utils/Types";

@Options({
  props: {
    activeBundle: [String, Number],
  },
  watch: {
    //Change selected level on active bundle change
    activeBundle: function () {
      if (this.activeBundle == null) {
        this.changeSelectedLevel(null);
        this.levels = [];
        return;
      }

      bundleAPI.getAllLevelsInBundle(this.activeBundle).then((value) => {
        this.levels = value;
        this.keepSelectedLevel();
      });
    },
  },
  data() {
    return {
      activeLevelId: null,
      levels: [],
    };
  },
  methods: {
    /**
     * Change selected level
     * @param key Selected level
     */
    changeSelectedLevel(key: string) {
      this.activeLevelId = key;
      if (key == "allLevels") {
        let levelIdList: number[] = [];
        this.levels.forEach((level: types.level) => {
          levelIdList.push(level.levelId);
        });
        this.$emit("updateLevel", levelIdList);
      } else if (key == null) {
        this.$emit("updateLevel", []);
      } else {
        this.$emit("updateLevel", [this.activeLevelId]);
      }
    },
    //Keep selected level
    keepSelectedLevel() {
      if (this.activeLevelId == "allLevels") {
        this.changeSelectedLevel("allLevels");
        return;
      }
      let levelIdList: number[] = [];
      this.levels.forEach((level: types.level) => {
        levelIdList.push(level.levelId);
      });
      if (!levelIdList.includes(this.activeLevelId)) {
        this.changeSelectedLevel(null);
      }
    },
  },
})
export default class LevelsBlock extends Vue {}
