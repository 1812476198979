
import { Options, Vue } from "vue-class-component";
import * as types from "@/utils/Types";

@Options({
  props: {
    slides: Array,
    levelStatsStudent: {} as types.levelStatsStudent,
  },
})
export default class SpecificStats extends Vue {}
