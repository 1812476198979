
import { Options, Vue } from "vue-class-component";
import { classAPI } from "@/utils/apiCalls";
import * as types from "@/utils/Types";

@Options({
  props: {
    activeClassroom: [String, Number],
  },
  watch: {
    //Change student on active classroom change
    activeClassroom() {
      if (this.activeClassroom == null) {
        this.changeSelectedStudent(null);
        return;
      }

      if (this.activeClassroom == "allClassrooms") {
        classAPI.getAllMyStudents().then((value) => {
          this.students = value;
          this.keepSelectedStudent();
        });
      } else {
        classAPI.getStudents(this.activeClassroom).then((value) => {
          this.students = value;
          this.keepSelectedStudent();
        });
      }
    },
  },
  data() {
    return {
      activeStudentId: null,
      students: [],
    };
  },
  methods: {
    /**
     * Change selected student
     * @param key Student
     */
    changeSelectedStudent(key: string | types.userInfo | null) {
      this.activeStudentId = key;
      if (key == "allStudents") {
        let studentIdList: number[] = [];
        this.students.forEach((student: types.userInfo) => {
          studentIdList.push(student.userId);
        });
        this.$emit("updateStudent", studentIdList);
      } else if (key == null) {
        this.$emit("updateStudent", []);
      } else this.$emit("updateStudent", [this.activeStudentId]);
    },
    //Keep seleccted student
    keepSelectedStudent() {
      if (this.activeStudentId == "allStudents") {
        this.changeSelectedStudent("allStudents");
        return;
      }
      let studentIdList: number[] = [];
      this.students.forEach((student: types.userInfo) => {
        studentIdList.push(student.userId);
      });
      if (!studentIdList.includes(this.activeStudentId)) {
        this.changeSelectedStudent(null);
      }
    },
  },
})
export default class StudentsBlock extends Vue {}
