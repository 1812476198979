
import { DoughnutChart } from "vue-chart-3";
import { Chart, ArcElement, DoughnutController, Tooltip } from "chart.js";
import { computed, ref, defineComponent, PropType } from "vue";

Chart.register(ArcElement, DoughnutController, Tooltip);
export default defineComponent({
  components: { DoughnutChart },
  props: {
    amountWorking: {
      required: true,
      type: Number as PropType<number> | undefined,
    },
    amountNotWorking: {
      required: true,
      type: Number as PropType<number> | undefined,
    },
  },
  watch: {
    //Update data on amount working change
    amountWorking: function () {
      this.updateData();
    },
    //Update data on amount not working change
    amountNotWorking: function () {
      this.updateData();
    },
  },
  mounted() {
    let translateObj = this as unknown as { $t: (s: string) => string };
    this.labels = [
      translateObj.$t("statistics.working"),
      translateObj.$t("statistics.notWorking"),
    ];
  },
  /**
   * Set up working solution chart
   * @returns { myData, updateData, options, labels }
   */
  setup(props) {
    const data = ref([props.amountWorking, props.amountNotWorking]);
    const labels = ref(["WW", "WW"]);
    const options = ref({
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
      },
    });

    const myData = computed(() => ({
      labels: labels.value,
      datasets: [
        {
          data: data.value,
          borderColor: "#5C576B",
          backgroundColor: ["#4CAB54", "#D34D4A"],
        },
      ],
    }));
    function updateData() {
      data.value = [props.amountWorking, props.amountNotWorking];
    }
    return { myData, updateData, options, labels };
  },
});
