
import { Options, Vue } from "vue-class-component";
import ClassroomsBlock from "@/components/classes/ClassroomsBlock.vue";
import StudentsBlock from "@/components/classes/StudentsBlock.vue";
import BundlesBlock from "@/components/classes/BundlesBlock.vue";
import LevelsBlock from "@/components/classes/LevelsBlock.vue";
import StatisticsBlock from "@/components/classes/StatisticsBlock.vue";
import { getUserInfo } from "@/utils/Functions";
import QuestionButton from "@/components/QuestionButton.vue";

@Options({
  mounted() {
    this.updateQuestionButton();
  },
  data() {
    return {
      activeClassroom: null,
      activeStudents: [],
      activeBundle: null,
      activeLevels: [],
      wikiSubjects: [],
    };
  },
  methods: {
    updateClassroom(variable: string) {
      this.activeClassroom = variable;
    },
    updateStudent(variable: number[]) {
      this.activeStudents = variable;
    },
    updateBundle(variable: number) {
      this.activeBundle = variable;
    },
    updateLevel(variable: number[]) {
      this.activeLevels = variable;
    },
    updateQuestionButton() {
      this.wikiSubjects.push(
        {
          wikiButton: {
            name: this.$t("wiki.classWiki"),
            item: "classWikiPage",
          },
        }

        //here you can add more page references
      );
    },
    getUserInfo,
  },
  components: {
    StatisticsBlock,
    LevelsBlock,
    StudentsBlock,
    BundlesBlock,
    ClassroomsBlock,
    QuestionButton,
  },
})
export default class Classes extends Vue {}
