
import { Options, Vue } from "vue-class-component";
import ChartWorkingSolution from "@/components/classes/statistics/ChartWorkingSolution.vue";
import ChartLang from "@/components/classes/statistics/ChartLang.vue";
import * as types from "@/utils/Types";

@Options({
  data() {
    return {
      langColors: [
        "#67C3CE",
        "#4CAB54",
        "#FBA64B",
        "#F28A1A",
        "#B0681A",
        "#D34D4A",
        "#ED6C84",
        "#EFEFEF",
      ],
    };
  },
  props: {
    slides: Array,
    statsType: String,
    levelStatsStudent: {} as types.levelStatsStudent,
    activeLevels: Array,
    activeStudents: Array,
  },
  watch: {
    levelStatsStudent(newValue) {
      this.setColorMemory(newValue?.percentageMemory);
      this.updateWidthBar(newValue?.percentageSuccessfullRun);
    },
  },
  mounted() {
    this.setColorMemory(this.levelStatsStudent?.percentageMemory);
    this.updateWidthBar(this.levelStatsStudent?.percentageSuccessfullRun);
  },
  components: {
    ChartWorkingSolution,
    ChartLang,
  },
  methods: {
    setColorMemory(percentage: number) {
      let bar = document.getElementById("memoryBar");
      percentage += 100;
      if (bar) {
        bar.style.backgroundColor =
          "rgb(" +
          Math.min(percentage * 1.19 - 40, 256) +
          "," +
          Math.min(256, 369 - percentage * 1.98) +
          "," +
          (130 - percentage * 0.46) +
          ")";
        bar.style.width = Math.max(20, Math.min(100, percentage - 100)) + "%";
      }
    },
    updateWidthBar(percentage: number) {
      let bar = document.getElementById("runBar");
      if (bar) {
        bar.style.width = Math.max(30, Math.min(100, percentage)) + "%";
      }
    },
  },
})
export default class PercentageStats extends Vue {}
