
import { Options, Vue } from "vue-class-component";
import SpecificStats from "@/components/classes/statistics/SpecificStats.vue";
import PercentageStats from "@/components/classes/statistics/PercentageStats.vue";
import GeneralStats from "@/components/classes/statistics/GeneralStats.vue";
import { progressAPI } from "@/utils/apiCalls";
import * as types from "@/utils/Types";

@Options({
  data() {
    return {
      slides: ["active", "", ""],
      statsType: undefined,
      levelStatsStudent: undefined,
      overlay: false,
    };
  },
  props: {
    activeStudents: Array,
    activeBundle: Number,
    activeLevels: Array,
  },
  watch: {
    //Update stats on active students change
    activeStudents() {
      this.$nextTick(this.updateStats());
    },
    //Update stats on active bundle change
    activeBundle() {
      this.$nextTick(this.updateStats());
    },
    //Update state on active levels change
    activeLevels() {
      this.$nextTick(this.updateStats());
    },
  },
  methods: {
    //Update statistics
    updateStats() {
      if (
        this.activeStudents.length == 0 ||
        this.activeBundle == null ||
        this.activeLevels.length == 0
      ) {
        this.statsType = undefined;
        return;
      }
      progressAPI
        .getStudentStats(
          this.activeStudents,
          this.activeBundle,
          this.activeLevels
        )
        .then((responseData) => {
          if (!responseData) {
            this.couldNotGetStats();
          } else
            this.levelStatsStudent = responseData as types.levelStatsStudent;

          if (this.activeStudents.length > 1) {
            if (this.activeLevels.length > 1)
              this.statsType = "allUsersAllLevels";
            else this.statsType = "allUsersOneLevel";
          } else {
            if (this.activeLevels.length > 1)
              this.statsType = "oneUserAllLevels";
            else this.statsType = "oneUserOneLevel";
          }
          if (
            this.activeStudents.length == 0 ||
            this.activeLevels.length == 0 ||
            this.activeBundle == null
          )this.statsType = undefined;
        console.log('lvlstatsstudents', this.levelStatsStudent);
        });
    },
    /**
     * Get world statistics
     * @returns Boolean
     */
    worldStats(): boolean {
      if (this.levelStatsStudent?.worldSpecificStats)
        return this.levelStatsStudent.worldSpecificStats.length > 0;
      return false;
    },
    /**
     * Go to other slide based on navigation selection
     * @param slideNumber Number of the selected slide
     */
    goToSlide(slideNumber: number) {
      for (let i = 0; i < this.slides.length; ++i) {
        if (i == slideNumber) {
          this.slides[i] = "active";
        } else {
          this.slides[i] = "";
        }
      }
    },
    couldNotGetStats() {
      // Trigger popup with error.
      if (
        !(
          this.activeStudents.length == 0 ||
          this.activeBundle == null ||
          this.activeLevels.length == 0
        )
      )
        this.overlay = true;
      // Replace mockdata with default values (mainly empty strings and 0's) when backend is done.
      const mockData: types.levelStatsStudent = {
        worldType: "BotzQuest",
        worldSpecificStats: [
          {
            statName: "stepsTaken",
            type: "number",
            value: 0,
          },
          {
            statName: "timesDied",
            type: "number",
            value: 0,
          },
        ],
        avarageAttempts: 0,
        totalAttempts: 0,
        avarageTimesOpened: 0,
        totalTimesOpened: 0,
        avarageDuration: 0,
        totalDuration: 0,
        percentageMemory: 100,
        medalObtained: "silver",
        percentageSuccessfullRun: 100,
        amountWorkingSolution: 0,
        amountAnySolution: 0,
        numberOfAdvancements: 0,
        amountUsedLanguages: [
          { lang: "en", amount: 1 },
          { lang: "pg", amount: 0 },
          { lang: "nl", amount: 0 },
          { lang: "sp", amount: 0 },
        ],
      };
      this.levelStatsStudent = mockData;
    },
  },
  components: {
    SpecificStats,
    PercentageStats,
    GeneralStats,
  },
})
export default class StatisticsBlock extends Vue {}
