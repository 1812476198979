
import { Options, Vue } from "vue-class-component";
import { classAPI } from "@/utils/apiCalls";

@Options({
  data() {
    return {
      activeClassroom: {
        id: null,
        name: null,
      },
      classrooms: [],
    };
  },
  methods: {
    /**
     * Set selected classroom
     * @param id Classroom id
     * @param name Classroom name
     */
    setSelected(id: string, name: string) {
      this.activeClassroom.id = id;
      this.activeClassroom.name = name;
      this.$emit("updateClassroom", this.activeClassroom.id);
    },
    /**
     * Change selected classroom
     * @param event Change classroom event
     */
    changeSelectedClassroom(event: Event) {
      this.activeClassroom.id = (event.target as HTMLInputElement).value;
      this.$emit("updateClassroom", this.activeClassroom.id);
    },
    //Get all classrooms of current school
    getClassrooms() {
      classAPI.getAllClassroomsOfSchool().then((value) => {
        this.classrooms = value;
      });
    },
  },
  mounted() {
    this.getClassrooms();
  },
})
export default class ClassroomsBlock extends Vue {}
