
import { Options, Vue } from "vue-class-component";
import { classAPI } from "@/utils/apiCalls";
import * as types from "@/utils/Types";

@Options({
  props: {
    activeClassroom: [String, Number],
    activeStudents: Array,
  },
  watch: {
    //Change bundle on active classroom change
    activeClassroom: function () {
      if (this.activeClassroom == null) {
        this.changeSelectedBundle(null);
        return;
      }

      if (this.activeClassroom == "allClassrooms") {
        classAPI.getAllMyBundles().then((value) => {
          this.bundles = value;
          this.keepSelectedBundle();
        });
      } else {
        classAPI.getBundles(this.activeClassroom).then((value) => {
          this.bundles = value;
          this.keepSelectedBundle();
        });
      }
    },
  },
  data() {
    return {
      activeBundleId: null,
      bundles: [],
    };
  },
  methods: {
    /**
     * Change selected bundle
     * @param key Bundle id
     */
    changeSelectedBundle(key: string) {
      this.activeBundleId = key;
      this.$emit("updateBundle", this.activeBundleId);
    },
    //Keep selected bundle
    keepSelectedBundle() {
      let bundleIdList: number[] = [];
      this.bundles.forEach((bundle: types.bundle) => {
        bundleIdList.push(bundle.bundleId);
      });
      if (!bundleIdList.includes(this.activeBundleId)) {
        this.changeSelectedBundle(null);
      }
    },
  },
})
export default class BundlesBlock extends Vue {}
