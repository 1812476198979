
import { DoughnutChart } from "vue-chart-3";
import { Chart, ArcElement, DoughnutController } from "chart.js";
import { computed, ref, defineComponent, PropType } from "vue";

Chart.register(ArcElement, DoughnutController);
export default defineComponent({
  components: { DoughnutChart },
  props: {
    langs: {
      required: true,
      type: Array as PropType<{ lang: string; amount: number }[]> | undefined,
    },
  },
  watch: {
    //Update data on langs change
    langs: function () {
      this.updateData();
    },
  },
  //Set up language chart
  setup(props) {
    const data = ref([1] as number[]);
    if (props.langs)
      props.langs.forEach((lang, index) => {
        data.value[index] = lang.amount;
      });
    const labels: string[] = [];
    if (props.langs)
      props.langs.forEach((lang, index) => {
        labels[index] = lang.lang;
      });
    const options = ref({
      responsive: true,
      plugins: {
        legend: {
          position: "none",
        },
      },
    });

    const myData = computed(() => ({
      labels: labels,
      datasets: [
        {
          data: data.value,
          borderColor: "#5C576B",
          backgroundColor: [
            "#67C3CE",
            "#4CAB54",
            "#FBA64B",
            "#F28A1A",
            "#B0681A",
            "#D34D4A",
            "#ED6C84",
            "#EFEFEF",
          ],
        },
      ],
    }));
    /**
     * Update data language chart
     * @returns { myData, updateData, options }
     */
    function updateData() {
      if (props.langs)
        props.langs.forEach((lang, index) => {
          labels[index] = lang.lang;
        });
      if (props.langs)
        props.langs.forEach((lang, index) => {
          data.value[index] = lang.amount;
        });
      else data.value = [];
    }
    return { myData, updateData, options };
  },
});
